<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link
        to="/"
        class="brand-logo d-flex align-center"
      >
        <v-img
          :src="appLogo"
          max-height="57px"
          max-width="180px"
          alt="logo"
          contain
          class=""
        ></v-img>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
            />

            <!-- tree -->
            <v-img
              height="226"
              width="300"
              class="auth-tree"
              src="@/assets/images/misc/tree-4.png"
            ></v-img>

            <!-- 3d character -->
            <div class="d-flex align-center h-full pa-16 pe-0">
              <v-img
                contain
                max-width="100%"
                height="692"
                class="auth-3d-group"
                :src="require(`@/assets/images/3d-characters/group-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pa-10 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    {{ t('Welcome to') }} {{ appName }}! 👋🏻
                  </p>
                  <p class="mb-2">
                    {{ t('Please sign-in to your account and start the adventure') }}
                  </p>
                </v-card-text>

                <v-card-text>
                  <v-form
                    ref="loginForm"
                    @submit.prevent="handleFormSubmit"
                  >
                    <div class="d-flex align-center flex-wrap mb-4">
                      <v-text-field
                        v-model="email"
                        regular
                        name="email"
                        :label="t('Email')"
                        :placeholder="t('Email')"
                        :error-messages="errorMessages.email"
                        :rules="[validators.required, validators.emailValidator]"
                        hide-details="auto"
                        class="mb-4"
                      ></v-text-field>

                      <v-expand-transition>
                        <div
                          v-if="isPinVisible"
                          class="w-100"
                        >
                          <v-otp-field
                            ref="pinRef"
                            v-model="pin"
                            length="4"
                            name="pin"
                            :label="t('PIN')"
                            :error-messages="errorMessages.pin"
                            :rules="[validators.required]"
                            :handle-callback="handleFormSubmit"
                          ></v-otp-field>
                        </div>
                      </v-expand-transition>
                    </div>

                    <div class="d-flex align-center flex-wrap mb-4">
                      <v-btn
                        text
                        x-small
                        class=""
                        @click="togglePinVisibility"
                      >
                        <span v-if="!isPinVisible">{{ t('I already have a PIN') }}</span>
                        <span v-else>{{ t("I don't have a PIN") }}</span>
                      </v-btn>
                    </div>

                    <v-btn
                      block
                      color="primary"
                      type="submit"
                      :loading="loading"
                      :disabled="loading"
                    >
                      <span v-if="!isPinVisible">{{ t('Request Verification PIN') }}</span>
                      <span v-else>{{ t('Login') }}</span>
                    </v-btn>
                  </v-form>
                </v-card-text>

                <!-- create new account  -->
                <!--                 <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                  <p class="mb-0 me-2">
                    New on our platform?
                  </p>
                  <router-link :to="{name:'auth-register'}">
                    Create an account
                  </router-link>
                </v-card-text> -->

                <!-- divider -->
                <!--                 <v-card-text class="d-flex align-center mt-2">
                  <v-divider></v-divider>
                  <span class="mx-5">or</span>
                  <v-divider></v-divider>
                </v-card-text> -->

                <!-- socail links -->
                <!-- <v-card-actions class="d-flex justify-center">
                  <v-btn
                    v-for="link in socialLink"
                    :key="link.icon"
                    icon
                    class="ms-1"
                  >
                    <v-icon :color="$vuetify.theme.dark ? link.colorInDark:link.color">
                      {{ link.icon }}
                    </v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card> -->
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, getCurrentInstance, computed } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import { useUtils } from '@core/libs/i18n'

// import axios from '@axios'
import { useRouter } from '@core/utils'
import themeConfig from '@themeConfig'

export default {
  setup() {
    const { t } = useUtils()
    const { router } = useRouter()

    const vm = getCurrentInstance().proxy

    const pinRef = ref(null)
    const loginForm = ref(null)

    const isPinVisible = ref(false)

    const pin = ref('')
    const email = ref('')
    const errorMessages = ref([])

    const loading = computed(() => vm.$store.getters['auth/loading'])

    const resetForm = (resetValues = false) => {
      loginForm.value.resetErrorBag()
      loginForm.value.resetValidation()

      errorMessages.value = []

      if (resetValues) loginForm.value.reset()
    }

    const handleFormSubmit = () => {
      errorMessages.value = []
      const isFormValid = loginForm.value.validate()

      if (!isFormValid) return

      const formData = new FormData(loginForm.value.$el)
      formData.set('pin', pin.value)

      if (isPinVisible.value) {
        vm.$store
          .dispatch('auth/login', formData)
          .then(res => {
            if (res) {
              router.replace('/')
            }
          })
          .catch(err => {
            if (err?.data?.errors) {
              errorMessages.value = err.data.errors
            }
          })
      } else {
        vm.$store
          .dispatch('auth/email', formData)
          .then(res => {
            isPinVisible.value = res
          })
          .catch(err => {
            if (err?.data?.errors) {
              errorMessages.value = err.data.errors
            }
          })
      }
    }

    const togglePinVisibility = () => {
      resetForm()

      pin.value = ''
      isPinVisible.value = !isPinVisible.value
    }

    return {
      handleFormSubmit,
      togglePinVisibility,

      isPinVisible,

      email,
      pin,
      errorMessages,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },
      validators: {
        required,
        emailValidator,
      },

      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      loginForm,

      loading,

      t,

      pinRef,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
